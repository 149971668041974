const DocumentViewRouter = [
  {
    name: 'document_list',
    path: '/documents',
    props: true,
    default: false,
    meta: {
      breadCrumb: 'list'
    },
    component: () =>
      import(/* webpackChunkName: "document_views" */ '@/views/documents/DocumentList.vue')
  },
  {
    name: 'signature_view',
    path: '/documents/:documentId',
    props: true,
    default: false,
    meta: {
      breadCrumb: 'list'
    },
    component: () =>
      import(/* webpackChunkName: "document_views" */ '@/views/pdf_viewer/SignatureView.vue')
  },

]

export default DocumentViewRouter
