const DocumentEmailSignatureView = [
	{
		name: "email_signature_view",
		path: "/shares/:tokenString",
		props: true,
		default: false,
		component: () =>
			import(
				/* webpackChunkName: "signature_view" */ "@/views/signatures/TokenSignatureView.vue"
			)
	}
];

export default DocumentEmailSignatureView;
