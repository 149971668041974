import Vue from "vue"
import App from "./App.vue"
import "@/commons/TimeFilters.js"
import "@/commons/StringFilters.js"
import responsive from "@/commons/responsive.js"
import VueSignaturePad from "vue-signature-pad"
import store from "./store"
import router from "./router"

import Buefy from "buefy"

import axios from "axios"
import VueCookies from "vue-cookies"
import wysiwyg from "vue-wysiwyg"

Vue.use(Buefy)
Vue.use(VueSignaturePad)
Vue.use(responsive)
Vue.use(VueCookies)
Vue.use(wysiwyg, {
  hideModules: {
    "code": true,
    "headings": true,
    "table": true,
    "image": true,
    "separator": true,
  },
})

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app")
