const PublicViewRouter = [
	{
		name: "notification_email_verify",
		path: "/public/users/activation/:uid/:token",
		props: true,
		default: true,

		component: () =>
			import(
				/* webpackChunkName: "public_view" */ "@/views/public_views/NewUserActivationEmailVerification.vue"
			)
	}
];

export default PublicViewRouter;
