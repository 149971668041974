import Vue from 'vue'

var capitalize = function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}
var trucateFilter = function (text, length, clamp) {
  clamp = clamp || '...'
  length = length || 30
  var node = document.createElement('div')
  node.innerHTML = text
  var content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
}

Vue.filter('truncate', trucateFilter)
Vue.filter('capitalize', capitalize)


Vue.mixin({
  methods: {
    isEmpty(obj) {
      if (obj == null) return true
      return Object.keys(obj).length === 0
    }
  }
})
