import Vue from 'vue'
import moment from 'moment'

var localTimeFilter = function (value) {
  if (!value) return ''
  return moment
    .utc(value)
    .local()
    .format('DD/MM/YYYY HH:mm')
}

Vue.filter('localTime', localTimeFilter)
