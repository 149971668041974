import Vue from 'vue'
import Vuex, { Store } from 'vuex'

Vue.use(Vuex)

export default new Store({
  state: {
    user: null,
    currentModule: null,
    theme: null,
  },
  getters: {
    user: function (state) {
      return state.user
    },
    currentModule: function (state) {
      return state.currentModule
    },
    theme: function (state) {
      return state.theme
    },
  },
  mutations: {
    setUser(state, item) {
      state.user = item
    },
    setCurrentModule(state, item) {
      state.currentModule = item
    },
    setAppTheme(state, item) {
      state.theme = item
    },
  },
  actions: {
    setUser(context, item) {
      context.commit('setUser', item)
    },
    setCurrentModule(context, item) {
      context.commit('setCurrentModule', item)
    },
  }
})
