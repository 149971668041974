import Vue from "vue";
import VueRouter from "vue-router";
import axiosDefaults from "axios/lib/defaults";
import DocumentViewRouter from "@/views/documents/routes";
import PreferenceViewRouter from "@/views/users/routes";
import DocumentEmailSignatureView from "@/views/signatures/routes";
import PublicViewRouter from "@/views/public_views/routes";

Vue.use(VueRouter);

const publicPages = ["/login", "/register"];

let routes = [
	{
		name: "",
		path: "/",
		component: () => import("@/views/dashboard/Dashboard.vue"),
		props: true,
		children: [
			{
				name: "dashboard",
				path: "/",
				component: () => import("@/views/documents/DocumentList.vue")
			}
		]
	},
	{
		name: "login",
		path: "/login",
		component: () => import("@/views/auth/Login.vue"),
		props: true
	},
	{
		path: "*",
		component: () => import("@/components/PageNotFound.vue")
	}
];

routes[0].children.push.apply(routes, PublicViewRouter);
routes[0].children.push.apply(routes[0].children, DocumentViewRouter);
routes[0].children.push.apply(routes[0].children, PreferenceViewRouter);
routes.push.apply(routes, DocumentEmailSignatureView);

const router = new VueRouter({
	mode: "history",
	routes
});

router.beforeEach((to, from, next) => {
  if(to.path.startsWith("/public") || to.path.startsWith("/share")){
    next();
    return;
  }

	let authRequired = window.$cookies.get("token");
	let authRefreshRequired = window.$cookies.get("refresh");

	if (
		(!authRequired || !authRefreshRequired) &&
		!publicPages.includes(to.path)
	) {
		next({
			path: "/login",
			query: {
				redirect: to.path
			}
		});
	} else {
		// TODO: Might not be an efficient way to handle it
		if (window.$cookies.get("token")) {
			axiosDefaults.headers.common["Authorization"] =
				"Bearer " + window.$cookies.get("token");
		}
		next();
	}
});

export default router;
