<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view :key="$route.params.id " />
    </transition>
  </div>
</template>

<script>
// import "./../node_modules/bulma/css/bulma.css";

export default {
  name: "app",

  components: {},

  data: () => ({
    //
  })
};
</script>
<style lang="scss" >
@import "~bulma/sass/utilities/_all";
@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');


$primary: #464646;
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);
$family-primary: "Inter", Helvetica, Arial, sans-serif;
$body-font-size: 0.9em;
$small-font-size: 0.75em;

$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
  "twitter": (
    $twitter,
    $twitter-invert
  )
);

$family-primary: "Public Sans", Helvetica, Arial, sans-serif;
$body-font-size: 0.98em;
$small-font-size: 0.82em;
#app {
  font-family: "Public Sans", Helvetica, Arial, sans-serif;
  font-size: $body-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

.navbar-brand,
.navbar-tabs {
  min-height: 2.8rem !important;
}

.c-fade {
  display: inherit !important; /* override v-show display: none */
  transition: opacity 0.5s;
}

.c-fade[style*="display: none;"] {
  opacity: 0;
  pointer-events: none; /* disable user interaction */
  user-select: none; /* disable user selection */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.container {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;


@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.logo {
  font-family: 'Pacifico', cursive;
  font-size: 25px;
}
</style>