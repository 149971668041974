const PreferenceViewRouter = [
	{
		name: "settings",
		path: "/preferences",
		props: true,
		default: false,
		component: () =>
			import(
				/* webpackChunkName: "users_preferences" */ "@/views/users/Settings.vue"
			)
  },
];

export default PreferenceViewRouter;
